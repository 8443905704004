import Element from 'element-ui'

export default {
  delConfirm (delObj) {
    Element.MessageBox.confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      delObj.onOk()
    })
  }
}
