import http from '@/http'

export default {
  updateBatch (ids) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysCloudCardComboUnBind/unbindBatch', { ids: ids.join(',') })
        .then((res) => {
          resolve(res)
        })
    })
  }
}
