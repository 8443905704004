import { Message } from 'element-ui'

let messageInstance = null
const resetMessage = (options) => {
    if (messageInstance) {
      // 只允许一个弹窗
      // messageInstance.close()
    }
    options.showClose = true
    messageInstance = Message(options)
  }
;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
export default resetMessage
