import http from '@/http'
import { MessageBox } from 'element-ui'

export default {
  updateBatch (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerDevice/updateBatch', params).then((res) => {
        resolve(res)
      })
    })
  },
  deleteBatch (ids) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/deleteBatch', { ids: ids.join(',') })
        .then((res) => {
          resolve(res)
        })
    })
  },
  insertBatch (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerDevice/insertBatch', params).then((res) => {
        resolve(res)
      })
    })
  },
  countCustomerDevice (params) {
    return new Promise((resolve, reject) => {
      http.post('/countCustomerDevice/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  downTemplate (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerDevice/downTemplate', params).then((res) => {
        resolve(res)
      })
    })
  },
  delete (params) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(`是否确定删除设备${params.imei}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          http.post('/customerDevice/delete', { id: params.id }).then((res) => {
            resolve(res)
          })
        })
        .catch(() => {
        })
    })
  },
  import (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/import', params, {
          otherUrl: true
        })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  update (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/update', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  customerDeviceInsertV2 (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/V2/insert', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  customerDeviceUpdateV2 (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/V2/update', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getTestNum () {
    return new Promise((resolve, reject) => {
      http.post('/customerDevice/testingNum/select').then((res) => {
        resolve(res)
      })
    })
  },
  getLicenseUse (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerDevice/license/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  updateDeviceModelJointId (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/V2/updateDeviceModelJointId', params, {
          isJSON: true
        })
        .then((res) => {
          resolve(res)
        })
    })
  },
  getDeviceEncryptStr (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/customerDevice/V2/getDeviceEncryptStr', params)
        .then((res) => {
          resolve(res)
        })
    })
  }
}
