import http from '@/http'

export default {
  findByImei (params) {
    return new Promise((resolve, reject) => {
      http.post('/billImei/findByImei', params).then((res) => {
        resolve(res)
      })
    })
  },
  findAll (params) {
    return new Promise((resolve, reject) => {
      http.post('/billImei/findAll', params).then((res) => {
        resolve(res)
      })
    })
  }
}
