/**
 * 将字典转为下来选项的通用方法
 * @param data
 * @returns {[{label: string, type: string, value: string},...*]}
 * @private
 */
const _handlerFilterList = (data) => {
  data.map((item) => {
    item.value = item.type.toString()
    return item
  })
  return [
    {
      label: '全部',
      value: '',
      type: ''
    },
    ...data
  ]
}

const state = {
  projectTypeList: [
    // {
    //   type: 1,
    //   label: '自定义'
    // },
    {
      type: 4,
      label: 'License'
    }, // {
    //   type: 3,
    //   label: '设备'
    // },
    {
      type: 2,
      label: '可设置项目'
    }
    // {
    //   type: 5,
    //   label: '应用版本'
    // }
  ],
  state: [
    {
      type: 3,
      label: '已付款'
    },
    {
      type: 1,
      label: '待出账'
    },
    {
      type: 2,
      label: '待付款'
    }
  ],
  payType: [
    {
      type: 1,
      label: '对公转账'
    },
    {
      type: 2,
      label: '线上支付'
    }
  ]
}

const getters = {
  projectTypeListFilter () {
    return function ({ projectType }) {
      if (projectType) {
        return state.projectTypeList.find((item) => item.type === projectType)
      }
    }
  },
  projectTypeList (state) {
    /**
     * excludeCustom 不包含自定义
     */
    return function ({ excludeCustom }) {
      if (excludeCustom) {
        return state.projectTypeList.filter((item) => item.type !== 1)
      } else {
        return state.projectTypeList
      }
    }
  },
  projectType (state) {
    return state.projectTypeList
  },
  state (state) {
    return state.state
  },
  stateFilterList (state) {
    return _handlerFilterList(state.state)
  },
  payType (state) {
    return state.payType
  }
}

const mutations = {}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
