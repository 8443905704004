<!-- 设置 账号管理 -->
<template>
  <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="passwordObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
        status-icon
    >
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input
            type="password"
            v-model="passwordObj.oldPassword"
            autocomplete="off"
            show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
            type="password"
            v-model="passwordObj.password"
            autocomplete="off"
            show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      passwordObj: {
        password: '',
        oldPassword: ''
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          }
        ]
      },
      id: 0
    }
  },

  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
    },
    show () {
      return new Promise(resolve => {
        this.initData()
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          resolve()
        })
      })
    },
    initData () {
      this.passwordObj = {
        password: '',
        oldPassword: ''
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.sysUser.updatePassword({
            password: this.$rsa(this.passwordObj.password),
            oldPassword: this.$rsa(this.passwordObj.oldPassword)
          }).then(() => {
            this.close()
            this.$emit('on-submit')
            this.$message.success('修改成功')
          })
        }
      })
    }
  },
  mounted () {
  }
}
</script>
