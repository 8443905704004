const state = {
  periodType: [
    {
      type: 1,
      label: '永久'
    },
    {
      type: 2,
      label: '30天'
    },
    {
      type: 3,
      label: '180天'
    },
    {
      type: 4,
      label: '365天'
    },
    {
      type: 5,
      label: '7天'
    }
  ],
  // 电信运营商
  telecomType: [
    {
      type: 1,
      label: '电信'
    },
    {
      type: 2,
      label: '移动'
    }
  ],
  portAuthType: [
    {
      type: 1,
      label: 'Web'
    },
    {
      type: 2,
      label: 'IOS'
    },
    {
      type: 3,
      label: 'Android'
    }
  ]
}

const getters = {
  periodType (state) {
    return state.periodType
  },
  telecomType (state) {
    return state.telecomType
  },
  portAuthType (state) {
    return state.portAuthType
  }
}

const mutations = {}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
