import http from '@/http'

export default {
  updateUniqueId (params) {
    return new Promise((resolve, reject) => {
      http.post('/customer/updateUniqueId', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  }
}
