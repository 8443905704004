export default {
  /**
   * 获取当天日期
   * @param cutOff 分隔号
   * @returns {string}
   */
  getToday (cutOff = '-') {
    const dateObj = new Date()
    return this.formatDay(dateObj).join(cutOff)
  },
  /**
   * 获取传入日期的过后几天日期数组
   * @param today
   * @param daysCount
   * @param cutOff
   * @returns {string[]}
   */
  getNextDays ({
    today,
    daysCount,
    cutOff = '-'
  }) {
    if (!today) {
      today = this.getToday('/')
    }
    const dateObj = new Date(today)
    const arr = [this.formatDay(dateObj).join(cutOff)]
    for (let i = 0; i < daysCount; i++) {
      const days = dateObj.setDate(dateObj.getDate() + 1)
      arr.push(this.formatDay(new Date(days)).join(cutOff))
    }
    return arr
  },
  /**
   * 获取传入日期的前几天日期数组
   * @param today
   * @param daysCount
   * @param cutOff
   * @returns {string[]}
   */
  getBeforeDays ({
    today,
    daysCount,
    cutOff = '-'
  }) {
    if (!today) {
      today = this.getToday('/')
    }
    const dateObj = new Date(today)
    const arr = [this.formatDay(dateObj).join(cutOff)]
    for (let i = 0; i < daysCount; i++) {
      const days = dateObj.setDate(dateObj.getDate() - 1)
      arr.push(this.formatDay(new Date(days)).join(cutOff))
    }
    return arr
  },
  /**
   * 传入date对象 返回格式化后的数组
   * @param dateObj
   * @returns {[number, number, number]}
   */
  formatDay (dateObj) {
    const year = dateObj.getFullYear()
    const month =
      dateObj.getMonth() + 1 > 10
        ? dateObj.getMonth() + 1
        : '0' + (dateObj.getMonth() + 1)
    const date =
      dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate()
    return [year, month, date]
  },
  formatTime (dateObj) {
    let h = dateObj.getHours()
    let m = dateObj.getMinutes()
    let s = dateObj.getSeconds()
    if (h < 10) {
      h = '0' + h
    }
    if (m < 10) {
      m = '0' + m
    }
    if (s < 10) {
      s = '0' + s
    }
    return [h, m, s]
  },
  getCurDtm (cutOff = '-') {
    return this.getToday(cutOff) + ' ' + this.formatTime(new Date()).join(':')
  },
  /**
   * 传入秒数 返回时分秒
   * @param value
   * @returns {string}
   */
  formatSeconds (value) {
    let theTime = parseInt(value) // 秒
    let middle = 0 // 分
    let hour = 0 // 小时

    if (theTime > 60) {
      middle = parseInt(theTime / 60)
      theTime = parseInt(theTime % 60)
      if (middle > 60) {
        hour = parseInt(middle / 60)
        middle = parseInt(middle % 60)
      }
    }
    let result = '' + parseInt(theTime) + ''
    if (theTime < 10) {
      result = `00:0${parseInt(theTime)}`
    } else {
      result = `00:${parseInt(theTime)}`
    }

    if (middle > 0) {
      result = `${parseInt(theTime)}`
      if (middle < 10) {
        result = '0' + parseInt(middle) + ':' + result
      } else {
        result = '' + parseInt(middle) + ':' + result
      }
    }
    if (hour > 0) {
      result = '' + parseInt(hour) + ':' + result
    }
    return result
  },
  computedDay (startDtm, endDtm) {
    return parseInt(Math.abs(startDtm - endDtm) / 1000 / 60 / 60 / 24)
  }
}
