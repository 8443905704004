import http from '@/http'

export default {
  login (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/login', params).then((res) => {
        resolve(res)
      })
    })
  },
  getTenantHost (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/getTenantHost', params).then((res) => {
        resolve(res)
      })
    })
  },
  updateUserPassword (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/updateUserPassword', params).then((res) => {
        resolve(res)
      })
    })
  },
  updatePassword (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/updatePassword', params).then((res) => {
        resolve(res)
      })
    })
  },
  getSimManageHost (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/getSimManageHost', params).then((res) => {
        resolve(res)
      })
    })
  },
  accountUpdate (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/account/update', params).then((res) => {
        resolve(res)
      })
    })
  },
  accountSelect (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysUser/account/select', params).then((res) => {
        resolve(res)
      })
    })
  }
}
