<!--  -->
<template>
  <div class="h-full flex flex-col" v-loading="tableIsLoading">
    <div
        class="flex flex-col align-items-center quick-operator-box fixed top-2 left-2/4 -translate-x-2/4 transform shadow-xl py-3 px-6 rounded-2xl rounded-t-none z-10"
        style="background: #e9edf3; border: 1px solid rgb(187 204 235)"
        v-showQuickOperatorBox
    >
      <!--      $slots['quick-operator-box']-->
      <div class="flex flex-row align-items-center">
        <div class="text-sm text-gray-500 px-2 select-none w-20 text-center">
          操作区：
        </div>
        <div id="quick-operator-box">
          <slot name="quick-operator-box"></slot>
        </div>
      </div>
      <div
          v-if="multipleSelectionList.size > 0"
          class="mt-4 text-xs select-none text-center"
      >
        当前已选 {{ multipleSelectionList.size }} 条数据
        <span
            @click="cancelMultipleSelectionList"
            class="text-blue-400 ml-2 cursor-pointer hover:underline text-center"
        >取消全部已选</span
        >
        <!--        <span @click="showMultipleSelection" class="text-blue-400 ml-2">查看已选数据</span>-->
      </div>
    </div>
    <el-card
        shadow="never"
        class="mb-4"
        v-if="showFilter && needShowFilter"
        title="条件筛选"
    >
      <div class="filter-box w-full bg-white mb-2">
        <el-form
            ref="form"
            label-width="96px"
            label-position="right"
            size="mini"
            inline
            class="divide-x divide-gray-100"
        >
          <template v-for="(columns, index) in filterObjProps">
            <el-form-item
                v-if="columns.filterType === 'select'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <el-select
                  v-model="requestParams[columns.filterProp]"
                  size="mini"
              >
                <el-option
                    v-for="(filterItem, filterIndex) in columns.filtersList"
                    :key="filterIndex"
                    :value="filterItem.value"
                    :label="filterItem.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
                v-if="columns.filterType === 'radio'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <el-radio-group
                  v-model="requestParams[columns.filterProp]"
                  size="mini"
              >
                <el-radio-button
                    v-for="(filterItem, filterIndex) in columns.filtersList"
                    :key="filterIndex"
                    :label="filterItem.value"
                >
                  {{ filterItem.label }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </template>

          <template v-for="(columns, index) in myColumns">
            <el-form-item
                v-if="columns.filterType === 'radio'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <el-radio-group v-model="requestParams[columns.prop]" size="mini">
                <el-radio-button
                    v-for="(filterItem, filterIndex) in columns.filtersList"
                    :key="filterIndex"
                    :label="filterItem.value"
                >
                  {{ filterItem.label }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item
                v-if="columns.filterType === 'multipleSelect'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <el-select
                  v-model="requestParams[columns.filterProp || columns.prop]"
                  collapse-tags
                  multiple
                  placeholder="请选择"
                  clearable
              >
                <el-option
                    v-for="item in columns.filtersList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
                v-if="columns.filterType === 'select'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <el-select
                  v-model="requestParams[columns.filterProp || columns.prop]"
                  collapse-tags
                  placeholder="请选择"
                  clearable
              >
                <el-option
                    v-for="item in columns.filtersList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
                v-if="columns.filterType === 'customFilter'"
                :key="index"
                class="mr-6"
            >
              <div slot="label" class="text-xs">{{ columns.label }}：</div>
              <slot :name="columns.filterSlotName"></slot>
            </el-form-item>
          </template>
        </el-form>
        <div class="border-t border-gray-100 w-full pt-4 flex flex-row">
          <el-button-group>
            <!--          <el-button size="mini" type="primary" icon="el-icon-search" @click="reGetData">筛选结果</el-button>-->
            <el-button
                size="mini"
                icon="el-icon-circle-close"
                @click="clearFilter"
            >重置过滤条件
            </el-button>
          </el-button-group>
          <!--          <div class="text-xs ml-4 flex align-items-center">-->
          <!--            当前筛选条件：-->
          <!--          </div>-->
        </div>
      </div>
    </el-card>
    <div class="operator-box flex flex-row justify-between align-items-center">
      <div class="left-operator">
        <slot name="left-operator"></slot>
      </div>
      <div class="right-operator flex flex-row">
        <span
            class="flex text-xs text-blue-900 font-bold align-items-center cursor-pointer"
            @click="showFilter = !showFilter"
            v-if="needShowFilter"
        >
          <i class="el-icon-setting mr-0.5"></i>
          <template v-if="!showFilter">更多筛选</template>
          <template v-if="showFilter">隐藏筛选</template>
        </span>
        <div class="search px-3 w-64" v-if="searchObj.columnList.length > 0">
          <el-input
              @clear="clearSearchValue"
              clearable
              v-model="searchObj.searchValue"
              class=""
              :placeholder="`输入${searchObj.fieldLabel}搜索`"
              size="mini"
          >
            <el-button
                class=""
                slot="append"
                icon="el-icon-search"
                size="mini"
                @click="search"
            ></el-button>
          </el-input>
        </div>
        <div class="refresh-box pl-2" v-if="refreshBtn">
          <el-button icon="el-icon-refresh" size="mini" @click="reGetData"
          >刷新
          </el-button>
        </div>
      </div>
    </div>
    <!--    <InfiniteScrollTable-->
    <!--        ref="ref_infinite_table"-->
    <!--        :getMoreData="getMoreDataFn"-->
    <!--        :scrollData="tableData"-->
    <!--        :loadConfig="loadConfig"-->
    <!--        @setTableData="setTableDataFn">-->

    <div class="v-box h-5/6" ref="vBox">
      <virtual-scroll
          :data="scrollData"
          :item-size="40"
          :key="undefined"
          key-prop="id"
          :virtualized="scrollData.length > 100"
          @change="(renderData) => (virtualList = renderData)"
      >
        <el-table
            style="position: sticky; top: 0; width: 100%"
            ref="AppTable"
            :data="virtualList"
            :row-key="tableRowKey"
            :stripe="tableStripe"
            size="small"
            @selection-change="handleSelectionChange"
            @cell-mouse-enter="handlerCellMouseEnter"
            @cell-mouse-leave="handlerCellMouseLeave"
            class="w-full"
            :max-height="maxHeight"
            :row-class-name="tableRowClassName"
            :header-row-style="headerRowStyle"
            :height="tableHeight"
        >
          <template v-for="(column, columnIndex) in myColumns">
            <el-table-column
                v-if="column.type === 'default' && column.display"
                :key="columnIndex"
                :prop="column.prop"
                :width="column.width"
                :min-width="column.minWidth"
                :label="column.label"
            >
            </el-table-column>
            <!--          <el-table-column-->
            <!--              v-if="column.type === 'selection' && column.display"-->
            <!--              type="selection"-->
            <!--              :reserve-selection="reserveSelection"-->
            <!--              width="55"-->
            <!--              :key="columnIndex"-->
            <!--              :label="column.label"-->
            <!--          >-->
            <!--          </el-table-column>-->
            <el-table-column
                v-if="column.type === 'selection' && column.display"
                :reserve-selection="reserveSelection"
                width="70"
                :key="columnIndex"
                :label="column.label"
            >
              <template v-slot:header>
                <el-checkbox
                    v-model="checkAll"
                    :indeterminate="indeterminate"
                    @change="handleCheckAllChange"
                ></el-checkbox>
              </template>
              <template v-slot="{ row }">
                <el-checkbox
                    @change="handleSelectionChangeCustom(row, $event)"
                    v-model="row.selected"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
                v-if="column.type === 'dict' && column.display"
                :key="columnIndex"
                :prop="column.prop"
                :width="column.width"
                :label="column.label"
            >
              <template v-slot="{ row }">
                <!--            <slot :name="column.slotName" :row="row" :dictContent="getDictLabel(column.prop, row[column.prop])"></slot>-->
                {{ getDictLabel(column.prop, row[column.prop]) }}
              </template>
            </el-table-column>
            <el-table-column
                v-if="column.type === 'customSlot' && column.display"
                :key="columnIndex"
                :prop="column.prop"
                :width="column.width"
                :label="column.label"
                :header-align="column.headerAlign"
            >
              <template v-slot="{ row }">
                <slot :name="column.slotName" :row="row"></slot>
              </template>
              <!--            v-if="column.headerSlotName"-->
              <template #header>
                <template v-if="column.headerSlotName">
                  <slot :name="column.headerSlotName" :column="column"></slot>
                </template>
                <template v-if="!column.headerSlotName && !column.filterType">
                  <!--如果是没有自定义表头并且没有表头筛选的情况下，这里是表头的默认显示-->
                  {{ column.label }}
                </template>
                <template v-if="column.filterType === 'customFilter'">
                  <!--为了兼容之前版本的customFilter模式-->
                  {{ column.label }}
                </template>
                <!--表头单选筛选模式-->
                <template v-if="column.filterType === 'headerFilterRadio'">
                  <el-popover
                      v-if="column.filtersList.length > 0"
                      placement="bottom"
                      trigger="hover"
                  >
                    <span slot="reference">
                      {{ column.label }}
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-radio-group
                        v-model="requestParams[column.prop]"
                        class="flex flex-col divide-y divide-gray-100"
                        @change="headerFilterRadioOnChange(column, $event)"
                    >
                      <el-radio
                          v-for="(item, index) in column.filtersList"
                          :key="index"
                          :label="item.value"
                          :value="item.value"
                          class="py-3 text-sm flex align-items-center w-full"
                      >
                        <span class="text-xs">
                          {{ item.label }}
                        </span>
                      </el-radio>
                    </el-radio-group>
                  </el-popover>
                  <template v-else>
                    {{ column.label }}
                  </template>
                </template>
                <!--表头多选筛选模式-->
                <template v-if="column.filterType === 'headerFilterCheckbox'">
                  <el-popover
                      v-if="column.filtersList.length > 0"
                      placement="bottom"
                      trigger="hover"
                  >
                    <span slot="reference">
                      {{ column.label }}
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-checkbox-group
                        v-model="requestParams[column.filterProp]"
                        class="flex flex-col"
                        @change="headerFilterCheckBoxOnChange(column, $event)"
                    >
                      <el-checkbox
                          v-for="(item, index) in column.filtersList"
                          :key="index"
                          :label="item.value"
                          :value="item.value"
                          class="py-1 text-sm"
                      >
                        {{ item.label }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-popover>
                  <template v-else>
                    {{ column.label }}
                  </template>
                </template>
                <!--表头时间筛选模式-->
                <template v-if="column.filterType === 'headerFilterDatePicker'">
                  <el-popover placement="bottom" trigger="hover">
                    <span slot="reference">
                      {{ column.label }}
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-date-picker
                        @change="headerFilterDatePickerOnChange(column, $event)"
                        :picker-options="headerFilterDatePickerOptions"
                        v-model="column.datePickerObj"
                        type="datetimerange"
                        align="right"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                    >
                    </el-date-picker>
                  </el-popover>
                </template>
                <div
                    v-if="
                    column.filtersList &&
                    column.filtersList.length > 0 &&
                    requestParams[column.prop] &&
                    (column.filterType === 'headerFilterRadio' ||
                      column.filterType === 'headerFilterCheckbox')
                  "
                >
                  <p class="truncate">
                    [{{
                      handlerGetRequestParamsLabel(
                          column.filtersList,
                          requestParams[column.prop]
                      )
                    }}]
                  </p>
                </div>
                <div
                    v-if="
                    column.filterType === 'headerFilterDatePicker' &&
                    column.datePickerObj &&
                    column.datePickerObj.length > 0
                  "
                >
                  <p slot="reference" class="truncate">
                    [{{ handlerGetFilterDatePicker(column) }}]
                  </p>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </virtual-scroll>
    </div>
    <!--    </InfiniteScrollTable>-->

    <el-pagination
        background
        v-if="!customTableData"
        class="mt-4"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-sizes="[15, 30, 50, 100, 500, 1000, 2000, 3000, 5000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>
<style lang="less" rel="stylesheet/less">
.el-table__body-wrapper {
  //height: calc(100% - 40px);
  overflow-y: auto !important;
}

.el-table th {
  vertical-align: top !important;
}
</style>
<style scoped lang="less" rel="stylesheet/less">
.v-box {
  overflow-y: auto;
  position: relative;
}
</style>
<script type="text/ecmascript-6">
import timeUtils from '@utils/timeUtils'
import VirtualScroll from 'el-table-virtual-scroll'

export default {
  data () {
    return {
      tableData: [],
      myColumns: [],
      page: 0,
      size: 15,
      pages: 1, // 总页数
      total: 1, // 总数量
      multipleSelection: [], // 当前页的多选数组
      multipleSelectionList: new Map(), // 表格多选时的数组
      searchObj: { // 搜索
        fieldLabel: '',
        columnList: [],
        searchValue: ''
      },
      showFilter: false, // 展示更多筛选
      scrollData: [],
      virtualList: [], // 虚拟表格数据
      itemHeight: 41,
      scrollDebounce: false, // 滚动事件防抖
      curScrollTop: 0,
      checkAll: false,
      indeterminate: false,
      tableIsLoading: false, // 表格加载状态
      tableGetDataDebounce: null, // 表格获取数据防抖
      headerFilterDatePickerOptions: {
        // 时间筛选器的快捷选择
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  props: {
    controller: String, // 表格的controller名
    tableColumns: Array, // 表格的列
    height: Number, // 表格高度
    selection: Boolean, // 是否需要多选
    customTableData: Array, // 自定义数据
    selectionList: {
      // 已选选项
      type: Array,
      default: () => {
        return []
      }
    },
    requestParams: {
      // 自定义请求参数
      type: Object,
      default: () => {
        return {}
      }
    },
    filterData: Array, // 筛选框数据
    curHoverCell: Object, // 当前鼠标所在的cell
    searchObjProps: {
      // 由父级传入的搜索字段
      type: Array,
      default: () => {
        return []
      }
    },
    filterObjProps: {
      // 由父级传入的过滤字段
      type: Array,
      default: () => {
        return []
      }
    },
    defaultGetData: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    reserveSelection: {
      // 翻页时保持已选内容 需指定row-key字段
      type: Boolean,
      default: () => {
        return false
      }
    },
    tableRowKey: {
      // 指定表格唯一值字段
      type: String,
      default: () => {
        return 'id'
      }
    },
    maxHeight: {},
    tableHeight: {
      default: () => {
        return '70vh'
      }
    },
    tableRowClassName: {
      type: Function,
      default: () => {
        return () => {
        }
      }
    },
    tableStripe: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    enableVTable: {
      // 是否开启虚拟表格功能
      type: Boolean,
      default: () => {
        return false
      }
    },
    refreshBtn: {
      // 是否显示刷新按钮
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  directives: {
    showQuickOperatorBox: {
      bind: (el) => {
        el.style.display = 'none'
      },
      update: (el, binding) => {
        const opEl = el.querySelector('#quick-operator-box')
        if (opEl) {
          window.setTimeout(() => {
            if (opEl.children.length > 0) {
              el.style.display = 'block'
            } else {
              el.style.display = 'none'
            }
          })
        }
      }
    }
  },
  components: {
    VirtualScroll
  },
  computed: {
    needShowFilter () {
      const arr = this.myColumns.filter(item => (item.filterType === 'customFilter' || item.filterType === 'filter'))
      return arr.length > 0 || this.filterObjProps.length > 0
    }
    // checkAll () {
    // 判断当前页的checkbox状态是否为全选
    // const result = this.tableData.filter(item => item.selected)
    // return result.length === this.tableData.length
    // }
  },
  watch: {
    tableColumns: {
      immediate: true,
      handler: 'handlerColumns'
    },
    customTableData: {
      immediate: true,
      handler: 'handlerCustomTableData'
    },
    // selectionList: {
    //   immediate: true,
    //   handler: 'handlerSelectionList'
    // },
    requestParams: {
      immediate: false,
      deep: true,
      handler: 'handlerRequestParams'
    },
    tableData: {
      handler: 'handlerTableData'
    }
  },
  methods: {
    getData () {
      this.tableIsLoading = true

      if (this.tableGetDataDebounce) {
        console.info(`${this.controller} 接口进入防抖`)
        clearTimeout(this.tableGetDataDebounce)
      }
      this.tableGetDataDebounce = setTimeout(() => {
        this.tableGetDataDebounce = null
        return new Promise(resolve => {
          if (this.customTableData) {
            // 勾选多选选项
            this.$nextTick(() => {
              this.initMultipleSelection()
            })
            this.tableIsLoading = false
          } else {
            this.$api.page(this.controller, Object.assign({}, {
              size: this.size,
              page: this.page,
              query: this.searchObj.searchValue
            }, this.requestParams)).then(res => {
              this.pages = res.data.pages
              this.total = res.data.total
              this.tableData = res.data.content.map(item => {
                item.selected = false
                return item
              })
              this.setRenderData()
              // 勾选多选选项
              this.$nextTick(() => {
                this.initMultipleSelection()
              })
              this.$emit('on-getData')
              resolve()
            }).finally(() => {
              this.tableIsLoading = false
            })
          }
        })
      }, 500)
    },
    handleSizeChange (val) {
      this.size = val
      this.page = 0
      this.refresh()
    },
    /**
     * 页码数发生改变事件
     */
    handleCurrentChange (val) {
      this.page = val - 1
      this.refresh()
    },
    hasScrolled (element, direction) {
      if (direction === 'vertical') {
        return element.scrollHeight > element.clientHeight
      } else if (direction === 'horizontal') {
        return element.scrollWidth > element.clientWidth
      }
    },
    handlerColumns () {
      this.myColumns = []
      if (this.selection) {
        this.myColumns.push({
          type: 'selection'
        })
      }
      this.tableColumns.forEach(column => {
        if (!column.type) {
          column.type = 'default'
        }
        if (!('display' in column)) {
          column.display = true
        }
        // column.headerAlign = 'right'
      })
      this.myColumns = [...this.myColumns, ...this.tableColumns]
      // 处理需要搜索的字段
      this.handlerSearch()
    },
    // 注册表格滚动到底部的事件
    addListen () {
      const el = document.querySelector('.el-table__body-wrapper')
      el.onscroll = () => {
        const height = el.offsetHeight
        const scrollTop = el.scrollTop
        const scrollHeight = el.scrollHeight
        if (height + scrollTop === scrollHeight) {
          this.tableBottomEvent()
        }
      }
    },
    tableBottomEvent () {
      this.page += 1
      this.getData()
    },
    /**
     * 搜索功能
     * */
    search () {
      this.page = 0
      this.clearSelection()
      this.getData()
      this.$emit('on-search')
    },
    /**
     * 清空搜索内容
     * */
    clearSearchValue () {
      this.clearSelection()
      this.getData()
    },
    /**
     * 清空表格并重新获取数据
     * */
    refresh () {
      this.tableData = []
      this.getData()
    },
    /**
     * 清空数据并重新获取
     * oldScrollTop 用于存放刷新前的滚动条高度，刷新完成后自动跳转到该位置
     * setTimeout内的方法待优化
     * */
    async reGetData () {
      const oldScrollTop = parseInt(this.curScrollTop)
      this.tableData = []
      await this.getData()
      window.setTimeout(() => {
        const wrapper = document.querySelector('.v-box')
        wrapper.scrollTop = oldScrollTop
      }, 100)
    },
    /**
     * 清空所有数据
     * */
    initData () {
      this.page = 0
      this.total = 1
      this.tableData = []
      this.multipleSelection = []
      this.multipleSelectionList = new Map()
    },
    /**
     * 表格多选时触发事件
     * @param list
     */
    handleSelectionChange (list) {
      this.multipleSelection = list
      // this.multipleSelectionList = list
      this.$emit('on-handleSelection-change', this.multipleSelection)
    },
    /**
     * 表格多选时触发事件
     * 自定义事件，替代el-table事件
     */
    handleSelectionChangeCustom (row, event) {
      if (event) {
        // 勾选选中事件
        this.multipleSelectionList.set(row.id, row)
      } else {
        // 取消勾选事件
        if (this.multipleSelectionList.get(row.id)) {
          this.multipleSelectionList.delete(row.id)
        }
      }
      this.emitMultipleSelection()
    },
    /**
     * 全选事件
     */
    handleCheckAllChange (val) {
      if (val) {
        this.tableData.forEach(item => {
          item.selected = true
          this.multipleSelectionList.set(item.id, item)
        })
      } else {
        this.tableData.forEach(item => {
          item.selected = false
          if (this.multipleSelectionList.get(item.id)) {
            this.multipleSelectionList.delete(item.id)
          }
        })
      }
      this.emitMultipleSelection()
    },
    emitMultipleSelection () {
      const arr = []
      for (const key of this.multipleSelectionList) {
        arr.push(key[1])
      }
      // indeterminate
      // checkAll
      const curPageSelectCount = this.tableData.filter(item => item.selected)
      if (curPageSelectCount.length > 0) {
        if (curPageSelectCount.length === this.tableData.length) {
          // 代表当前页为全选
          this.checkAll = true
          this.indeterminate = false
        } else {
          this.indeterminate = true
        }
      } else if (arr.length > 0) {
        this.checkAll = false
        this.indeterminate = true
      } else {
        this.checkAll = false
        this.indeterminate = false
      }
      this.$emit('on-handleSelection-change', arr)
    },
    /**
     * 加载完数据后，如果本页数据有已存在的勾选项，则勾选上
     */
    initMultipleSelection () {
      this.tableData.forEach(item => {
        const find = this.multipleSelectionList.get(item.id)
        if (find) {
          item.selected = true
        }
      })
      this.emitMultipleSelection()
    },
    handlerCustomTableData () {
      if (this.customTableData) {
        this.scrollData = this.customTableData.map(item => {
          item.selected = false
          return item
        })
      }
    },
    handlerSelectionList () {
      this.selectionList.forEach(item => {
        this.multipleSelectionList.set(item.id, item)
      })
    },
    handlerRequestParams () {
      this.page = 0
      this.reGetData()
    },
    handlerTableData () {
      if (this.customTableData) {
        // 有自定义数据的情况
        this.$emit('update:customTableData', this.tableData)
      }
      if (this.enableVTable) {
        // this.setWrapper()
        // this.addListenScroll()
      }
    },
    /**
     * 处理需要搜索的字段
     */
    handlerSearch () {
      this.searchObj.columnList = [...this.tableColumns.filter(item => item.search), ...this.searchObjProps]
      this.searchObj.fieldLabel = this.searchObj.columnList.map(item => item.label).join('、')
    },
    /**
     * 清除筛选条件
     */
    clearFilter () {
      const filterColumns = [...this.myColumns.filter(item => item.filterType), ...this.filterObjProps]
      for (const key in filterColumns) {
        if (filterColumns[key].filterType === 'radio') {
          this.requestParams[filterColumns[key].prop] = ''
        } else if (filterColumns[key].filterType === 'select') {
          this.requestParams[filterColumns[key].filterProp] = ''
        } else if (filterColumns[key].filterType === 'multipleSelect') {
          this.$set(this.requestParams, 'deviceModelList', [])
        } else if (filterColumns[key].filterType === 'customFilter') {
          this.requestParams[filterColumns[key].filterProp] = ''
        } else if (filterColumns[key].filterType === 'headerFilterRadio') {
          this.requestParams[filterColumns[key].prop] = ''
        } else if (filterColumns[key].filterType === 'headerFilterCheckbox') {
          this.requestParams[filterColumns[key].filterProp] = ''
        } else if (filterColumns[key].filterType === 'headerFilterDatePicker') {
          this.headerFilterDatePickerOnChange(filterColumns[key], null)
        }
      }
      this.$emit('on-clearFilter')
    },
    /**
     * 鼠标移入单元格
     */
    handlerCellMouseEnter (row, column, cell, event) {
      this.$emit('update:curHoverCell', row)
      // this.$emit('on-cell-mouse-enter', {
      //   row,
      //   column,
      //   cell,
      //   event
      // })
    },
    /**
     * 鼠标移出单元格
     */
    handlerCellMouseLeave (row, column, cell, event) {
      this.$emit('update:curHoverCell', {})
      // this.$emit('on-cell-mouse-leave', {
      //   row,
      //   column,
      //   cell,
      //   event
      // })
    },
    /**
     * 清空表格勾选选项
     */
    clearSelection () {
      this.tableData.forEach(item => {
        item.selected = false
      })
      this.multipleSelectionList = new Map()
      // this.$emit('on-handleSelection-change', [])
      // this.$refs.AppTable.clearSelection()
    },
    /**
     * 链接入Store中，根据type取找到对应的label内容
     * @param prop
     * @param type
     * @returns {string|*}
     */
    getDictLabel (prop, type) {
      const dictList = this.$store.getters[prop]
      if (dictList && dictList.length > 0) {
        return (dictList.find(item => item.type === type)).label
      }
      return ''
    },
    getMoreDataFn () {
    },
    setTableDataFn (arr) {
      this.scrollData = arr
    },
    setRenderData (start = 0) {
      // if (this.enableVTable) {
      //   if (this.tableData.length <= 15) {
      //     this.scrollData = this.tableData
      //   } else {
      //     this.scrollData = this.tableData.slice(start, start + 15)
      //   }
      // } else {
      //   this.scrollData = this.tableData
      // }
      this.scrollData = this.tableData
    },
    setWrapper () {
      this.$nextTick(() => {
        const wrapper = document.querySelector('.v-box')
        const vContent = document.createElement('div')
        const headerHeight = document.querySelector('.el-table__header-wrapper') && document.querySelector('.el-table__header-wrapper').offsetHeight
        vContent.id = 'vContent'
        vContent.style.width = '1px'
        vContent.style.height = ((this.tableData.length * this.itemHeight) - headerHeight) + 'px'
        vContent.style.position = 'absolute'
        vContent.style.left = '0'
        vContent.style.top = '0'
        vContent.style.zIndex = '-1'
        if (document.querySelector('#vContent')) {
          document.querySelector('#vContent').remove()
        }
        wrapper.append(vContent)
        this.$nextTick(() => {
          const wrapper = document.querySelector('.v-box')
          wrapper.scrollTop = this.curScrollTop
        })
      })
    },
    addListenScroll () {
      this.$nextTick(() => {
        if (document.querySelector('.v-box')) {
          const wrapper = document.querySelector('.v-box')
          const scrollFunc = () => {
            if (this.scrollDebounce) return
            this.scrollDebounce = true
            this.curScrollTop = wrapper.scrollTop // 当前滚动条离顶部的高度
            const itemHeight = this.itemHeight // item高度
            const startIndex = parseInt((this.curScrollTop / (itemHeight + 2)).toString())
            this.setRenderData(startIndex)
            setTimeout(() => {
              this.scrollDebounce = false
            }, 50)
          }
          wrapper.removeEventListener('scroll', scrollFunc)
          wrapper.addEventListener('scroll', scrollFunc)
        }
      })
    },
    /**
     * 查看已选数据
     */
    showMultipleSelection () {
      console.log(this.multipleSelectionList)
    },
    /**
     * 取消已选数据
     */
    cancelMultipleSelectionList () {
      this.clearSelection()
      this.emitMultipleSelection()
    },
    /**
     * 筛选条件改变回调
     * @param column
     * @param value
     */
    headerFilterCheckBoxOnChange (column, value) {
      if (column.filterOnChange) {
        column.filterOnChange(value)
      }
    },
    /**
     * 筛选条件改变回调
     * @param column
     * @param value
     */
    headerFilterRadioOnChange (column, value) {
      if (column.filterOnChange) {
        column.filterOnChange(value)
      }
    },
    /*
    * 处理表头已选的内容选项
     */
    handlerGetRequestParamsLabel (filtersList, key) {
      if (filtersList && filtersList.length > 0) {
        const filter = filtersList.find(item => item.value === key)
        if (filter) {
          return filter.label
        }
      }
    },
    handlerGetFilterDatePicker (column) {
      const result = []
      if (column.datePickerObj) {
        for (const index in column.datePickerObj) {
          const item = column.datePickerObj[index]
          const timeStr = (timeUtils.formatDay(item).join('-')) + ' ' + (timeUtils.formatTime(item).join(':'))
          result.push(timeStr)
        }
        return result.join(' 至 ')
      } else {
        return ''
      }
    },
    headerRowStyle () {
      return {
        padding: '10px 0'
      }
    },
    headerFilterDatePickerOnChange (column, $event) {
      for (const index in column.datePickerKey) {
        const paramsItem = column.datePickerKey[index]
        if ($event) {
          const timeStr = (timeUtils.formatDay($event[index])).join('-') + ' ' + (timeUtils.formatTime($event[index])).join(':')
          this.$set(this.requestParams, paramsItem, timeStr)
        } else {
          this.$set(this.requestParams, paramsItem, '')
          column.filtersList = []
          column.datePickerObj = []
        }
      }
    }
  },
  mounted () {
    // this.addListen()
    if (this.defaultGetData) {
      this.getData()
    }
  }
}
</script>
