import http from '@/http'

export default {
  /**
   * 处理服务器有多个线路ip的数据
   * @param computed
   * @returns {[]}
   */
  handlerComputedIpList (computed) {
    const ip = computed.outerNetIp ? computed.outerNetIp.split(',') : []
    const net = computed.broadBand ? computed.broadBand.split(',') : []
    const ipList = []
    if (ip.length === net.length) {
      ip.forEach((item, index) => {
        if (ip[index]) {
          ipList.push({
            ip: ip[index],
            net: net[index]
          })
        }
      })
    } else {
      console.log('IP与带宽大小数量不一致，请检查平台端录入数据')
    }
    return ipList
  },
  page (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysNode/page', params).then((res) => {
        res.data.content.forEach((item) => {
          item.list.forEach((computed) => {
            computed.ipInfo = this.handlerComputedIpList(computed)
          })
        })
        resolve(res)
      })
    })
  }
}
