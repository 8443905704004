import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import api from './api'
import appTable from '@/components/common/appTableV2'
import './styles/element-theme/index.css'
import oneMessage from './utils/oneMessage'

import './styles/main.css'

import blockTitle from '@/components/layout/blockTitle'
import InfiniteScrollTable from 'infinite-scroll-table'
import rsa from '@utils/rsa'

const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/legend')
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(api)
Vue.component('blockTitle', blockTitle)
Vue.component('AppTable', appTable)
Vue.use(InfiniteScrollTable)
Vue.use(rsa)
Vue.use(ElementUI)
Vue.prototype.$message = oneMessage
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
