import JSEncrypt from '../utils/jsencrypt.min'

const RSA_PUBLIC_KEY =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6pbuym6zxwQw7alnsZ2n1rOb2R88rOzfP/eRniLkceaf/J62yhvPKc+yexk4ySWQLOA/Eo3TLKYBolUmjWLQZRkAEKfQ7IKDul8l9Nq9slf5duAI7VxoHR4OwSYEe29M9y4kECSJ2PZrSaNGFojCIXVVLOxYfbBV3nsmvb29XewiWX6dQUeJM+MT70/Y+/jcM5EGConD9Nvokp7z3+IA6Y8So1axdoGSPUZmRx1pAtLJ94uR8RFJB085N53gBnihVKp6sw19m7SwVZah+JFF2zfP0u/xT/u1WDcAqaCwHuLIrNOvDrF+5y1mHS42yA1CkrYILPm1aBXU2aTt09j0xQIDAQAB'
const RSA = new JSEncrypt()
RSA.setPublicKey(RSA_PUBLIC_KEY)

function install (Vue) {
  Vue.prototype.$rsa = (text) => {
    return RSA.encrypt(text)
  }
}

export default install
