<!--  -->
<template>
  <!--  border-b border-gray-200-->
  <div class="app_block_title mb-4 flex align-items-center h-12">
    <div class="border-div"></div>
    {{ title }}
    <slot name="customContent"></slot>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.app_block_title {
  padding: 0 10px 0 15px;
  font-weight: 500;
  font-size: 14px;
  color: #234883;
  box-shadow: 0 2px 4px -4px hsl(0deg 0% 0%);
  //border-bottom: 1px solid #ddd;
}

.border-div {
  border-left: 4px solid #234883;
  height: 14px;
  margin-right: 8px;
}

//.app_block_title:after {
//  content: "";
//  width: 0;
//  height: 20px;
//  position: relative;
//  border: 2px solid #234883;
//  left: -10px;
//  top: -14px;
//  display: block;
//  border-radius: 3px;
//}
</style>
<script type="text/ecmascript-6">
export default {
  name: 'blockTitle',
  data () {
    return {}
  },
  props: {
    title: String
  },
  methods: {},
  mounted () {
  }
}
</script>
