const state = {
  userInfo: window.sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : {},
  AEPLogin: window.sessionStorage.getItem('AEPLogin')
    ? JSON.parse(sessionStorage.getItem('AEPLogin'))
    : null
}

const getters = {
  userInfo (state) {
    return state.userInfo
  },
  isLogin (state) {
    return state.userInfo.id
  },
  userToken (state) {
    return state.userInfo.token || ''
  },
  AEPLogin (state) {
    return state.AEPLogin
  },
  authList (state) {
    return state.userInfo && state.userInfo.authList
  }
}
const mutations = {
  setUserInfo (state, payload) {
    state.userInfo = payload
    window.sessionStorage.setItem('userInfo', JSON.stringify(payload))
  },
  logout () {
    state.userInfo = {}
    window.sessionStorage.setItem('userInfo', '')
    window.sessionStorage.setItem('permissionList', '')
    location.reload()
  },
  setAEPLogin (state, payload) {
    state.AEPLogin = payload
    window.sessionStorage.setItem('AEPLogin', JSON.stringify(payload))
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
