import http from '@/http'

export default {
  homeV2DeviceGroupSelect (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2DeviceGroup/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2Select (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2Bill (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2Bill/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2FlowPool (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2FlowPool/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2TrafficGraph (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2TrafficGraph/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2DeviceGraph (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2DeviceGraph/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2LicenseGroup (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2LicenseGroup/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  homeV2StorageGroupFindAll (params) {
    return new Promise((resolve, reject) => {
      http.post('/homeV2StorageGroup/findAll', params).then((res) => {
        resolve(res)
      })
    })
  }
}
