import http from '@/http'

export default {
  deviceSummary (params) {
    return new Promise((resolve, reject) => {
      http.post('/home/deviceSummary', params).then((res) => {
        resolve(res)
      })
    })
  },
  deviceChart (params) {
    return new Promise((resolve, reject) => {
      http.post('/home/deviceChart', params).then((res) => {
        resolve(res)
      })
    })
  },
  deviceModelChart (params) {
    return new Promise((resolve, reject) => {
      http.post('/home/deviceModelChart', params).then((res) => {
        resolve(res)
      })
    })
  },
  containerUseRank (params) {
    return new Promise((resolve, reject) => {
      http.post('/home/containerUseRank', params).then((res) => {
        resolve(res)
      })
    })
  }
}
