<template>
  <div id="app">
    <!--    <audio controls ref="audioPlayer"></audio>-->
    <template v-if="isLogin">
      <el-container>
        <el-aside width="auto">
          <menuList></menuList>
        </el-aside>
        <el-container class="right-column">
          <el-header height="42px">
            <headerBox></headerBox>
          </el-header>
          <el-main class="pt-0">
            <!--            <keep-alive-router-view :disabled="!$route.meta.keepAlive" />-->
            <!--            <transition-group name="fade" mode="out-in" tag="div">-->
            <div key="keep">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
            </div>
            <div key="not-keep">
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </div>
            <!--            </transition-group>-->
          </el-main>
        </el-container>
      </el-container>
    </template>
    <template v-if="!isLogin">
      <login :loginCoverImg="loginCoverImg"></login>
    </template>
  </div>
</template>

<style scoped lang="less" rel="stylesheet/less">
// fade styles!
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  background-color: #f1f4fa;
  height: 100vh;
  min-width: 1200px;
}

.right-column {
  height: 100vh;
  transition-duration: 0.42s;
  background: -webkit-gradient(linear,
  left bottom,
  left top,
  from(#f1f4fa),
  to(#d2dfed));
  background: linear-gradient(0deg, #f1f4fa, #d2dfed);
  background-size: 100% 70px;
  background-repeat: no-repeat;
}
</style>
<style lang="less">
svg {
  display: unset !important;
}
</style>

<script type="text/ecmascript-6">
import menuList from '@/layout/menu/menuListV1'
import headerBox from '@/layout/headerBox/headerBoxV1'
import login from './views/login/login'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      // 需要持久化的页面，需要指定组件内的name，不是指router内的name。
      keepAliveList: [
        'sysDevice',
        'createClient',
        'simList',
        'billList',
        'ClientDetails'
      ],
      loginCoverImg: ''
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'userToken'])
  },
  watch: {
    isLogin () {
      this.getDeviceTypeFindAll()
    }
  },
  components: {
    menuList,
    headerBox,
    login
  },
  methods: {
    getDeviceTypeFindAll () {
      if (this.isLogin) {
        this.$store.commit('getDeviceType', { _this: this })
      }
    },
    getWebInfo () {
      this.$api.sysUser.accountSelect().then(res => {
        const {
          labelName,
          labelIcon,
          loginCoverImg
        } = res.data
        document.title = labelName || 'Manager - 管理后台平台'

        this.loginCoverImg = loginCoverImg

        if (labelIcon) {
          const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = labelIcon
          document.getElementsByTagName('head')[0].appendChild(link)
        }
      })
    }
  },
  mounted () {
    this.getDeviceTypeFindAll()
    this.getWebInfo()
  }
}
</script>
