import http from '@/http'

export default {
  insert (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/deviceModelJoint/insert', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  },
  insertBatch (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/deviceModelJoint/insertBatch', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  },
  edit (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/deviceModelJoint/edit', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  },
  delete (params) {
    return new Promise((resolve, reject) => {
      http.post('/deviceModelJoint/delete', params).then((res) => {
        resolve(res)
      })
    })
  },
  setDefault (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/deviceModelJoint/setDefault', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  }
}
