import http from '@/http'
import elementUi from 'element-ui'

export default {
  async pay (params) {
    return new Promise((resolve, reject) => {
      try {
        elementUi.MessageBox.confirm('确认该客户已在线下付款？', '付款提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          http.post('/bill/pay', params, { isJSON: true }).then((res) => {
            resolve(res)
          })
        })
      } catch (e) {
      }
    })
  },
  async off (params) {
    return new Promise((resolve, reject) => {
      try {
        elementUi.MessageBox.confirm(
          '出账后将会发送订单推送至客户，确认出账？',
          '出账提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          http.post('/bill/off', params, { isJSON: true }).then((res) => {
            resolve(res)
          })
        })
      } catch (e) {
      }
    })
  },
  billProjectByBillNo (billNo) {
    return new Promise((resolve, reject) => {
      http.post('/bill/byBillNo', { billNo }).then((res) => {
        resolve(res)
      })
    })
  },
  /**
   * 撤销出账
   * @param params
   * @returns {Promise<unknown>}
   */
  offCancel (params) {
    return new Promise((resolve, reject) => {
      elementUi.MessageBox.confirm(
        '撤销出账后租户将无法查看该账单，确认撤销？',
        '撤销提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        http.post('/bill/offCancel', params, { isJSON: true }).then((res) => {
          resolve(res)
        })
      })
    })
  },
  updateState (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/updateState', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  },
  getDeviceBillList (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getDeviceBillList', params).then((res) => {
        resolve(res)
      })
    })
  },
  getLicenseBillList (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getLicenseBillList', params).then((res) => {
        resolve(res)
      })
    })
  },
  assignableLicenseBill (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/assignableLicenseBill', params).then((res) => {
        resolve(res)
      })
    })
  },
  getDeviceModel (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getDeviceModel', params).then((res) => {
        resolve(res)
      })
    })
  },
  getDeviceModelList (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getDeviceModelList', params).then((res) => {
        resolve(res)
      })
    })
  },
  licenseAssignableBillDeviceModel (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/bill/licenseAssignableBillDeviceModel', params)
        .then((res) => {
          resolve(res)
        })
    })
  },
  getImeiBindBillList (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getImeiBindBillList', params).then((res) => {
        resolve(res)
      })
    })
  },
  getLicenseProjectList (params) {
    return new Promise((resolve, reject) => {
      http.post('/bill/getLicenseProjectList', params).then((res) => {
        resolve(res)
      })
    })
  }
}
