import Axios from '@/http'

export default {
  /* 手机号登陆 */
  loginByPhone (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/loginByPhone', params).then((res) => {
        resolve(res)
      })
    })
  },
  /* 退出登录 */
  logout (params) {
    return new Promise((resolve, reject) => {
      Axios.post('login/logout', params).then((res) => {
        resolve(res)
      })
    })
  }
}
