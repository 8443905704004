import http from '@/http'

export default {
  select (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysForm/select', params).then((res) => {
        resolve(res)
      })
    })
  },
  sysDeviceTypeFindAll (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysForm/sysDeviceType/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  sysDeviceDeviceFindAll (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysForm/sysDevice/findAll', params).then((res) => {
        resolve(res)
      })
    })
  },
  insertBatch (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysForm/insertBatch', params).then((res) => {
        resolve(res)
      })
    })
  }
}
