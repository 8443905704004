const state = {
  // 服务器资源
  customerNode: {
    deployType: '1',
    sysNodeId: '',
    domainName: '',
    publicKey: '',
    privateKey: '',
    accessKeyId: '',
    secretKey: '',
    domainType: '1',
    isNotice: 1,
    uniqueId: ''
  },
  // 客户基础信息
  customerInfo: {
    name: '',
    logo: '',
    account: '',
    password: '',
    passwordConfirm: '',
    contactName: '',
    contactPhone: '',
    contactAddress: '',
    payType: 1,
    validDay: 0,
    coverUrl: '',
    portAuthList: []
  },
  // 存储容器
  customerContainer: {
    factoryKey: '',
    sysContainerId: ''
  },
  // 设备列表
  customerDeviceList: [],
  // 应用版本
  customerPanel: {
    panelType: ''
  },
  // 客户信息页-设备列表-选中的数组
  clientDeviceListMultipleList: []
}

const getters = {
  customerNode (state) {
    return state.customerNode
  },
  customerInfo (state) {
    return state.customerInfo
  },
  customerContainer (state) {
    return state.customerContainer
  },
  customerDeviceList (state) {
    return state.customerDeviceList
  },
  customerPanel (state) {
    return state.customerPanel
  },
  clientDeviceListMultipleList (state) {
    return state.clientDeviceListMultipleList
  }
}

const mutations = {
  setCustomerNode (state, payload) {
    state.customerNode = payload
  },
  setCustomerInfo (state, payload) {
    state.customerInfo = payload
  },
  setCustomerContainer (state, payload) {
    state.customerContainer = payload
  },
  setCustomerDeviceList (state, payload) {
    state.customerDeviceList = payload
  },
  setCustomerPanel (state, payload) {
    state.customerPanel = payload
  },
  initCustomNode (state, payload) {
    const {
      deployType,
      sysNodeId,
      domainName,
      publicKey,
      privateKey,
      accessKeyId,
      secretKey
    } = payload
    state.customerNode = {
      deployType: deployType || '1',
      sysNodeId: sysNodeId || '',
      domainName: domainName || '',
      publicKey: publicKey || '',
      privateKey: privateKey || '',
      accessKeyId: accessKeyId || '',
      secretKey: secretKey || '',
      domainType: '1',
      isNotice: 1,
      uniqueId: ''
    }
  },
  clearCustomNode (state) {
    state.customerNode = {
      deployType: '1',
      sysNodeId: '',
      domainName: '',
      publicKey: '',
      privateKey: '',
      accessKeyId: '',
      secretKey: ''
    }
  },
  clearCustomerInfo (state) {
    state.customerInfo = {
      name: '',
      logo: '',
      account: '',
      password: '',
      passwordConfirm: '',
      contactName: '',
      contactPhone: '',
      contactAddress: '',
      payType: '1',
      validDay: 0,
      coverUrl: ''
    }
  },
  clearCustomerContainer (state) {
    state.customerContainer = {
      factoryKey: '',
      sysContainerId: ''
    }
  },
  clearCustomerDeviceList (state) {
    state.customerDeviceList = []
  },
  clearCustomerPanel (state) {
    state.customerPanel = {
      panelType: ''
    }
  },
  initAllState () {
    // initCustomNode
    // initCustomerInfo
    // initCustomerContainer
    // initCustomerDeviceList
    // initCustomerPanel
  },
  setClientDeviceListMultipleList (state, payload) {
    state.clientDeviceListMultipleList = payload
  },
  clearClientDeviceListMultipleList (state, payload) {
    state.clientDeviceListMultipleList = []
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
