import http from '@/http'
import { MessageBox } from 'element-ui'

export default {
  update (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerInfo/update', params).then((res) => {
        resolve(res)
      })
    })
  },
  delete (params) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(`是否确定删除客户${params.name}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          http.post('/customerInfo/delete', { id: params.id }).then((res) => {
            resolve(res)
          })
        })
        .catch(() => {
        })
    })
  },
  getUserByUniqueId (params) {
    return new Promise((resolve) => {
      http.post('/customerInfo/findByUniqueId', params).then((res) => {
        resolve(res)
      })
    })
  }
}
