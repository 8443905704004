/**
 * 全局HTTP拦截
 * @param request
 * @param next
 */
// 安装完axios之后引入，qs也是axios里面的
import axios from 'axios'
import Qs from 'qs'
import store from '@store'
import { Notification } from 'element-ui'

let baseURL = ''
// 环境切换
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api/sys'
} else if (process.env.NODE_ENV === 'debug') {
  baseURL = '测试环境'
} else if (process.env.NODE_ENV === 'production') {
  baseURL = '/sys'
}

const service = axios.create({
  baseURL, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})
service.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
}
// request interceptor
service.interceptors.request.use(
  (config) => {
    // config.url.toUpperCase().indexOf('FILE') < 0 &&
    if (config.oosUpload) {
      config.headers = {
        'Content-Type': config.ContentType
      }
      config.baseURL = ''
    } else if (config.isUpload) {
      config.headers = {
        'Content-Type': 'multipart/form-data;'
      }
    } else if (!config.otherUrl || config.url === 'mediaFile/groupByDate') {
      if (
        config.url === 'devUserTaskAssign/updateStatus' ||
        config.url === 'serviceResource/updateDiySizeLimit'
      ) {
        for (const params in config.data) {
          if (
            config.data[params] === undefined ||
            config.data[params] === null ||
            config.data[params] === ''
          ) {
            delete config.data[params]
          }
        }
        config.data = Qs.stringify(config.data)
      } else {
        if (
          config.isJSON ||
          config.url.indexOf('/update') > 0 ||
          config.url.indexOf('/insert') > 0
        ) {
          config.headers = {
            'Content-Type': 'application/json'
          }
        } else {
          // 清除无用参数
          for (const params in config.data) {
            if (
              config.data[params] === undefined ||
              config.data[params] === null ||
              config.data[params] === ''
            ) {
              delete config.data[params]
            }
          }
          config.data = Qs.stringify(config.data)
        }
      }
    }
    if (store.getters.userToken) {
      // let each request carry token --['X-Token'] as a custom key.
      // please modify it according to the actual situation.
      config.headers.Authorization = `Bearer ${store.getters.userToken}`
    } else {
      // config.headers.Authorization = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjE2Mzk2MjQxMjcsImNyZWF0ZWQiOjE2MzcwMzIxMjczMjMsImlkIjoxfQ.VxKD_51S3r23yI0RM2SpswwrCibtGdLOCKYBsSltw27EHiE_9Zl4DcBy9WEtKv9veh7NnYM0nirnM71kpxZpsw'
    }

    if (config.xAmzAcl) {
      config.headers = {
        'Content-Type': config.fileSuffix,
        'x-amz-acl': 'public-read'
      }
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const data = response.data
      const code = data && data.code
      if (code === 200) {
        return Promise.resolve(data)
      } else if (code === 10002) {
        store.commit('logout')
      } else if (response.status === 200 && !code) {
        return Promise.resolve()
      } else {
        Notification.error({
          title: '温馨提示 ~',
          message: data.msg,
          duration: 3000
        })
        if (
          response.config.url ===
          '/api/admin/common/deviceOrDeviceUserChangeOrg'
        ) {
          return Promise.resolve(data)
        } else if (response.config.url === '/customerDevice/import') {
          return Promise.reject(data)
        } else {
          return Promise.reject(new Error(data.msg))
        }
      }
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
