import http from '@/http'

export default {
  getEncryptStr (params) {
    return new Promise((resolve, reject) => {
      http.post('/customerDeviceModel/V2/getEncryptStr', params).then(res => {
        resolve(res)
      })
    })
  }
}
