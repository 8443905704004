<!-- 菜单 -->
<template>
  <div class="menu h-screen relative">
    <el-menu
        text-color="#fff"
        active-text-color=""
        background-color=""
        unique-opened
        :collapse="isCollapse"
        :default-active="defaultActive"
    >
      <template v-for="(item, index) in authShowList">
        <el-menu-item
            :index="`${item.path}`"
            v-if="!item.child || item.child.length === 0"
            :key="index"
            @click="routerTo(item)"
        >
          <i v-if="item.icon" :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </el-menu-item>

        <el-submenu
            :index="`${item.path}`"
            v-if="item.child && item.child.length > 0"
            :key="index"
        >
          <template slot="title">
            <i v-if="item.icon" :class="item.icon"></i>
            <span style="font-weight: 600">{{ item.label }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                :index="`${menuChildItem.path}`"
                v-for="(menuChildItem, menuChildIndex) in item.child"
                @click="routerTo(menuChildItem)"
                :key="`child-${menuChildIndex}`"
            >
              {{ menuChildItem.label }}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
    <div
        @click="isCollapse = !isCollapse"
        class="text-white float-right mr-4 text-xs absolute bottom-4 right-0"
    >
      {{ isCollapse ? '展开 》' : '《 收起' }}
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.menu {
  background-size: 100% 100%;
  padding-top: 20px;
  background-image: url('~@/images/menu/menu.png');

  i {
    color: #fff;
  }

  span {
    padding-left: 6px;
  }
}
</style>
<style lang="less">
.menu:not(.el-menu--collapse) {
  //width: 265px;
  //min-height: 400px;
}

.menu {
  .el-menu,
  .el-menu-item {
    background-color: transparent !important;
    border-right: none;
  }

  .el-menu-item {
    width: 265px;
  }

  .el-submenu .el-menu-item {
    height: 56px;
    line-height: 56px;
    padding: 0 45px;
    min-width: 265px;
  }

  .el-submenu__title:hover {
    background-color: transparent !important;
  }

  .el-menu-item.is-active {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    color: #234883;
    margin-left: 5px;
    border: 0;
  }

  .el-menu-item.is-active,
  .el-submenu.is-disabled .el-menu-item.is-active {
    background-color: #fff !important;
  }

  .el-menu-item.is-active:after,
  .el-menu-item.is-active:before {
    width: 20px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    position: absolute;
    right: 0;
  }

  .el-menu-item.is-active:before {
    //background-image: url('/public/images/round.png');
    background-image: url('~@/images/menu/round.png');
    top: -12px;
  }

  .el-menu-item.is-active:after {
    //background-image: url('/public/images/round.png');
    background-image: url('~@/images/menu/round-1.png');
    top: 56px;
  }
}

.el-menu--popup {
  background-color: #234883 !important;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
</style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      authShowList: [],
      menuList: [
        {
          label: '首页',
          path: '/',
          icon: 'el-icon-s-home'
        },
        {
          label: '容器管理',
          path: 'xxx',
          icon: 'el-icon-s-grid',
          child: [
            {
              label: '存储容器',
              path: '/containerList'
            },
            {
              label: '密钥',
              path: '/secretKeyList'
            }
          ]
        },
        {
          label: '租户管理',
          path: 'client',
          icon: 'el-icon-s-custom',
          child: [
            {
              label: '添加租户',
              path: '/createClient'
            },
            {
              label: '租户列表',
              path: '/clientList'
            }
          ]
        },
        {
          label: '设备管理',
          icon: 'el-icon-s-cooperation',
          path: 'sysDevice',
          child: [
            {
              label: '设备类型',
              path: '/deviceModelList'
            },
            {
              label: '设备库',
              path: '/sysDevice'
            }
          ]
        },
        // {
        //   label: '资源',
        //   path: 'b',
        //   icon: 'el-icon-s-operation'
        // },
        // {
        //   label: 'SDK 接入中心',
        //   path: 'c',
        //   icon: 'el-icon-tickets'
        // },
        {
          label: '物联网卡',
          path: 'sim',
          icon: 'el-icon-cloudy',
          child: [
            {
              label: 'SIM卡管理',
              path: '/simList'
            }
            // {
            //   label: '套餐',
            //   path: '/simPackages'
            // }
          ]
        },
        {
          label: '帮助中心',
          path: 'helpCenter',
          icon: 'el-icon-help',
          child: [
            {
              label: '帮助中心',
              path: '/helpList'
            }
          ]
        },
        {
          label: '财务中心',
          path: 'finance',
          icon: 'el-icon-bank-card',
          child: [
            {
              label: '合同管理',
              path: '/agreementList'
            },
            {
              label: '平台账单',
              path: '/ptBillList'
            },
            {
              label: '产品规格',
              path: '/chargeItemList'
            },
            {
              label: '账单',
              path: '/billList'
            }
          ]
        },
        {
          label: '试用申请',
          path: 'trial',
          icon: 'el-icon-s-cooperation',
          child: [
            {
              label: '试用表单设置',
              path: '/trialForm?category=1'
            },
            {
              label: '加盟表单设置',
              path: '/trialForm?category=2'
            },
            {
              label: '试用申请列表',
              path: '/trialList?category=1'
            },
            {
              label: '加盟申请列表',
              path: '/trialList?category=2'
            }
          ]
        },
        {
          label: '系统设置',
          path: 'system',
          icon: 'el-icon-s-tools',
          child: [
            {
              label: '信息设置',
              path: '/infoManager'
            },
            {
              label: 'APP启动页',
              path: '/appLaunchImg'
            },
            {
              label: '账号管理',
              path: '/userManger'
            },
            {
              label: '角色管理',
              path: '/roleManager'
            },
            {
              label: '参数设置',
              path: '/paramsManager'
            },
            {
              label: '操作日志',
              path: '/log'
            }
          ]
        }
      ],
      isCollapse: false,
      defaultActive: ''
    }
  },
  watch: {
    '$route.path': function () {
      this.handlerCurPath()
    }
  },
  computed: {
    ...mapGetters(['authList'])
  },
  methods: {
    routerTo (item) {
      this.$router.push({
        path: item.path
      })
    },
    handlerCurPath () {
      setTimeout(() => {
        if (this.$route.meta.breadcrumb.length > 2) {
          // 三级菜单的情况下 用上级的menuListActive字段
          this.defaultActive = this.$route.meta.menuListActive
        } else {
          // 默认情况下用当前路由的fullPath
          this.defaultActive = this.$route.fullPath
        }
        console.log('defaultActive', this.defaultActive)
      }, 500)
    },
    handlerMenuList () {
      this.authShowList = []
      const paths = this.$router.options.routes.map(item => item.path)
      this.menuList.forEach(item => {
        const tmpItem = JSON.parse(JSON.stringify(item))
        tmpItem.child = []
        item.child && item.child.forEach(child => {
          // 页面path 可能存在参数，这里做一个?的切割
          const childPath = child.path.split('?')[0]
          if (paths.indexOf(childPath) > -1) {
            tmpItem.child.push(child)
          }
        })
        if (tmpItem.path === '/' && paths.indexOf('/') > -1) {
          this.authShowList.push(tmpItem)
        } else if (tmpItem.child.length > 0) {
          this.authShowList.push(tmpItem)
        }
      })
    }
  },
  mounted () {
    this.handlerCurPath()
    this.handlerMenuList()
  }
}
</script>
