import http from '@/http'
import { MessageBox } from 'element-ui'

export default {
  insertBatch (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysCloudCard/insertBatch', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  disabledBatch (params) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm('是否确定停用已选SIM卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          http
            .post('/sysCloudCard/disabledBatch', params)
            .then((res) => {
              resolve(res)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch(() => {
        })
    })
  },
  openBatch (params) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm('是否确定启用已选SIM卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          http
            .post('/sysCloudCard/openBatch', params)
            .then((res) => {
              resolve(res)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch(() => {
        })
    })
  }
}
