<!--  -->
<template>
  <div class="flex flex-row">
    <div
        class="left-box w-6/12 h-screen flex flex-col justify-center align-items-center"
    >
      <div class="img-box">
        <img v-if="loginCoverImg !== ''" :src="loginCoverImg" alt=""/>
        <!--        <img :src="require('../../images/login/logo.png')" alt="">-->
      </div>
    </div>
    <div
        class="right-box h-screen bg-white w-6/12 flex align-items-center justify-center"
    >
      <div class="login-box">
        <div class="text-3xl text-blue-900 text-center mb-4">管理后台登录</div>
        <el-form
            ref="form"
            label-position="top"
            label-width="80px"
            :model="form"
            class="w-full"
            :rules="rules"
        >
          <el-form-item prop="account">
            <span class="font-bold" slot="label">用户名</span>
            <el-input
                @keyup.enter.native="submit"
                class="w-full"
                v-model="form.account"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <span class="font-bold" slot="label">密码</span>
            <el-input
                type="password"
                @keyup.enter.native="submit"
                class="w-full"
                v-model="form.password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
                long
                class="w-full"
                type="primary"
                icon="el-icon-s-promotion"
                @click="submit"
            >登 录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.img-box {
  width: 60vh;
}

.login-box {
  width: 300px;
}
</style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      form: {
        account: '',
        password: '',
        loginType: 1
      },
      rules: {
        account: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  props: {
    loginCoverImg: {}
  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.password = this.$rsa(this.form.password)
          this.$api.sysUser.login(this.form).then(res => {
            this.$store.commit('setUserInfo', res.data)
            window.location.reload()
          })
        } else {
        }
      })
    }
  },
  mounted () {
  }
}
</script>
