<template>
  <div class="header-warpper flex flex-row justify-between">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item
          v-for="(item, index) in breadcrumb"
          :key="index"
          :to="{ name: item.pathName }"
      >
        <!--        <span @click="routerTo(item)">-->
        {{ item.label }}
        <!--        </span>-->
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info-box">
      <el-popover placement="bottom" width="400" trigger="hover">
        <div class="user-name font-bold xl:text-blue-900 p-2" slot="reference">
          {{ userInfo.account }}
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="p-4 relative">
          <el-button
              type="danger"
              size="mini"
              class="absolute right-2 top-2"
              icon="el-icon-s-promotion"
              @click="logout"
          >
            退出登录
          </el-button>
          <el-button
              size="mini"
              class="absolute right-2 top-12"
              icon="el-icon-lock"
              @click="changePsw"
          >
            修改密码
          </el-button>
          <div class="text-sm">当前登录用户</div>
          <div class="user-info-box text-xs mt-4">
            <div class="info-item flex flex-row">
              <div class="label w-16">用户名</div>
              <div class="value text-gray-700">
                {{ userInfo.account }}
              </div>
            </div>
            <div class="info-item flex flex-row mt-2">
              <div class="label w-16">用户名称</div>
              <div class="value text-gray-700">
                {{ userInfo.agUserName }}
              </div>
            </div>
            <div class="info-item flex flex-row mt-2">
              <div class="label w-16">地址</div>
              <div class="value text-gray-700">
                {{ userInfo.address }}
              </div>
            </div>
            <div class="info-item flex flex-row mt-2">
              <div class="label w-16">联系方式</div>
              <div class="value text-gray-700">
                {{ userInfo.realName }} - {{ userInfo.phone }}
              </div>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
    <editMyPasswordDialog ref="editMyPasswordDialog"></editMyPasswordDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.header-warpper {
  //height: 28px;
}

.breadcrumb {
  height: 42px;
  line-height: 42px;
}
</style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import editMyPasswordDialog from '../../components/sysUser/editMyPasswordDialog.vue'

export default {
  data () {
    return {}
  },
  components: {
    editMyPasswordDialog
  },
  computed: {
    ...mapGetters(['userInfo']),
    breadcrumb () {
      const arr = []
      if (this.$route.meta.breadcrumb) {
        return this.$route.meta.breadcrumb
      }
      return arr
    }
  },
  methods: {
    routerTo (item) {
      if (item.pathName) {
        this.$router.push({
          name: item.pathName
        })
      }
    },
    logout () {
      this.$store.commit('logout')
    },
    changePsw () {
      this.$refs.editMyPasswordDialog.show()
    }
  },
  mounted () {
  }
}
</script>
