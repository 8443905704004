import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)
const staticRoute = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login/login')
  // }
]
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: 'sys:homeData',
      menuListActive: '/'
    },
    component: () => import('../views/home/homeV2')
  },
  {
    path: '/simList',
    name: 'simList',
    meta: {
      auth: 'sys:sim',
      menuListActive: '/simList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: 'SIM卡管理'
        }
      ]
    },
    component: () => import('../views/sim/simListV2')
  },
  {
    path: '/simPackages',
    name: 'simPackages',
    meta: {
      menuListActive: '/simPackages',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '套餐'
        }
      ]
    },
    component: () => import('../views/sim/simPackages')
  },
  {
    path: '/clientList',
    name: 'clientList',
    meta: {
      auth: 'sys:customer',
      menuListActive: '/clientList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '租户列表'
        }
      ]
    },
    component: () => import('../views/client/clientList')
  },
  {
    path: '/clientDetails',
    name: 'clientDetails',
    meta: {
      auth: 'sys:customer',
      keepAlive: true,
      isBack: false,
      menuListActive: '/clientList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '租户列表',
          pathName: 'clientList'
        },
        {
          label: '客户详情'
        }
      ],
      keepPath: ['clientAddDevice', 'clientAddLicense']
    },
    component: () => import('../views/client/clientDetails')
  },
  {
    path: '/clientAddDevice',
    name: 'clientAddDevice',
    meta: {
      auth: 'sys:customer',
      menuListActive: '/clientList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '租户列表',
          pathName: 'clientList'
        },
        {
          label: '添加设备'
        }
      ]
    },
    component: () => import('../views/client/clientAddDeviceV2')
  },
  {
    path: '/clientAddLicense',
    name: 'clientAddLicense',
    meta: {
      auth: 'sys:customer',
      menuListActive: '/clientList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '租户列表',
          pathName: 'clientList'
        },
        {
          label: '添加授权'
        }
      ]
    },
    component: () => import('../views/client/clientAddLicense')
  },
  {
    path: '/createClient',
    name: 'createClient',
    meta: {
      auth: 'sys:customer',
      menuListActive: '/createClient',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '添加租户'
        }
      ]
    },
    component: () => import('../views/client/createClient')
  },
  {
    path: '/serviceNodeList',
    name: 'serviceNodeList',
    meta: {
      menuListActive: '/serviceNodeList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '集群节点'
        }
      ]
    },
    component: () => import('../views/serviceNode/serviceNodeList')
  },
  {
    path: '/containerList',
    name: 'containerList',
    meta: {
      auth: 'sys:container',
      menuListActive: '/containerList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '存储容器'
        }
      ]
    },
    component: () => import('../views/container/containerList')
  },
  {
    path: '/secretKeyList',
    name: 'secretKeyList',
    meta: {
      auth: 'sys:secretKey',
      menuListActive: '/secretKeyList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '密钥'
        }
      ]
    },
    component: () => import('../views/secretKey/secretKeyList')
  },
  {
    path: '/sysDevice',
    name: 'sysDevice',
    meta: {
      auth: 'sys:device',
      keepAlive: true,
      isBack: false,
      menuListActive: '/sysDevice',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '设备库'
        }
      ]
    },
    component: () => import('../views/sysDevice/sysDeviceListV2')
  },
  {
    path: '/deviceModelList',
    name: 'deviceModelList',
    meta: {
      auth: 'sys:deviceType',
      keepAlive: true,
      isBack: false,
      menuListActive: '/deviceModelList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '设备型号'
        }
      ]
    },
    component: () => import('../views/deviceModel/deviceModelList')
  },
  {
    path: '/deviceModelDetail',
    name: 'deviceModelDetail',
    meta: {
      auth: 'sys:deviceType',
      menuListActive: '/deviceModelList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '设备型号',
          pathName: 'deviceModelList'
        },
        {
          label: '设备详情'
        }
      ]
    },
    component: () => import('../views/deviceModel/deviceModelDetail')
  },
  {
    path: '/customPanel',
    name: 'customPanel',
    meta: {
      menuListActive: '/customPanel',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '客户面板'
        }
      ]
    },
    component: () => import('../views/customPanel/customPanelList')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/chargeItemList',
    name: 'chargeItemList',
    meta: {
      auth: 'sys:billItem',
      menuListActive: '/chargeItemList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '产品规格'
        }
      ]
    },
    component: () => import('../views/finance/chargeItemList')
  },
  {
    path: '/billList',
    name: 'billList',
    meta: {
      auth: 'sys:bill',
      keepAlive: true,
      isBack: false,
      menuListActive: '/billList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '账单'
        }
      ]
    },
    component: () => import('../views/finance/billList')
  },
  {
    path: '/billDetails',
    name: 'billDetails',
    meta: {
      auth: 'sys:bill',
      menuListActive: '/billDetails',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '账单流水'
        }
      ]
    },
    component: () => import('../views/finance/billDetails')
  },
  {
    path: '/billEditor',
    name: 'billEditor',
    meta: {
      menuListActive: '/billList',
      auth: 'sys:bill',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '账单',
          pathName: 'billList'
        },
        {
          label: '账单编辑'
        }
      ]
    },
    component: () => import('../views/finance/billEditor')
  },
  {
    path: '/billContent',
    name: 'billContent',
    meta: {
      menuListActive: '/billList',
      auth: 'sys:bill',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '账单',
          pathName: 'billList'
        },
        {
          label: '账单内容页'
        }
      ]
    },
    component: () => import('../views/finance/billContent')
  },
  {
    path: '/ptBillList',
    name: 'ptBillList',
    meta: {
      auth: 'sys:ptBill',
      menuListActive: '/ptBillList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '平台账单'
        }
      ]
    },
    component: () => import('../views/finance/ptBillList')
  },
  {
    path: '/ptBillContent',
    name: 'ptBillContent',
    meta: {
      auth: 'sys:ptBill',
      menuListActive: '/ptBillList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '平台账单',
          pathName: 'billList'
        },
        {
          label: '平台账单内容页'
        }
      ]
    },
    component: () => import('../views/finance/ptBillContent')
  },
  {
    path: '/userManger',
    name: 'userManger',
    meta: {
      auth: 'sys:user',
      menuListActive: '/userManger',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '账号管理'
        }
      ]
    },
    component: () => import('../views/system/userManger')
  },
  {
    path: '/infoManager',
    name: 'infoManager',
    meta: {
      auth: 'sys:infomanager',
      menuListActive: '/infoManager',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '信息设置'
        }
      ]
    },
    component: () => import('../views/system/infoManager')
  },
  {
    path: '/appLaunchImg',
    name: 'appLaunchImg',
    meta: {
      auth: 'sys:appLaunchImg',
      menuListActive: '/appLaunchImg',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: 'APP启动页'
        }
      ]
    },
    component: () => import('../views/system/appLaunchImg')
  },
  {
    path: '/roleManager',
    name: 'roleManager',
    meta: {
      auth: 'sys:role',
      menuListActive: '/roleManager',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '角色管理'
        }
      ]
    },
    component: () => import('../views/system/roleManager')
  },
  {
    path: '/trialForm',
    name: 'trialForm',
    meta: {
      auth: 'sys:form',
      menuListActive: '/trialForm',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '表单设置'
        }
      ]
    },
    component: () => import('../views/trial/trialForm.vue')
  },
  {
    path: '/trialList',
    name: 'trialList',
    meta: {
      auth: 'sys:trial',
      menuListActive: '/trialList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '试用申请'
        }
      ]
    },
    component: () => import('../views/trial/trialList.vue')
  },
  {
    path: '/agreementList',
    name: 'agreementList',
    meta: {
      auth: 'sys:agreement',
      menuListActive: '/agreementList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '合同管理'
        }
      ]
    },
    component: () => import('../views/finance/agreementList')
  },
  {
    path: '/agreementDetails',
    name: 'agreementDetails',
    meta: {
      auth: 'sys:agreement',
      menuListActive: '/agreementList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '合同管理',
          pathName: 'agreementList'
        },
        {
          label: '合同内容页'
        }
      ]
    },
    component: () => import('../views/finance/agreementDetails')
  },
  {
    path: '/agreementEdit',
    name: 'agreementEdit',
    meta: {
      auth: 'sys:agreement',
      menuListActive: '/agreementList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '合同管理',
          pathName: 'agreementList'
        },
        {
          label: '合同编辑页'
        }
      ]
    },
    component: () => import('../views/finance/agreementEdit')
  },
  {
    path: '/helpList',
    name: 'helpList',
    meta: {
      auth: 'sys:helpList',
      menuListActive: '/helpList',
      breadcrumb: [
        {
          label: '首页',
          pathName: 'Home'
        },
        {
          label: '帮助中心'
        }
      ]
    },
    component: () => import('../views/helpCenter/helpList.vue')
  }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const authList = store.getters.authList
const resultRoute = routes.reduce((prev, cur) => {
  if (authList && cur.meta && authList.indexOf(cur.meta.auth) > -1) {
    prev.push(cur)
  }
  return prev
}, [])

const router = new VueRouter({
  routes: [...staticRoute, ...resultRoute]
})

router.beforeEach((to, from, next) => {
  // const fromKeepPath = from.meta.keepPath || []
  // const toKeepPath = to.meta.keepPath || []
  // console.log('fromKeepPath', fromKeepPath)
  // console.log('toKeepPath', toKeepPath)
  // if (from.name === 'clientDetails' && to.name === 'clientAddDevice') {
  //   from.meta.keepAlive = true
  // } else if (to.name === 'clientDetails') {
  //   to.meta.keepAlive = from.name === 'clientAddDevice'
  // }
  // if (fromKeepPath.length > 0) {
  //   from.meta.keepAlive = fromKeepPath.indexOf(to.name) > -1
  // } else if (toKeepPath.length > 0) {
  //   // to.meta.keepAlive = false
  // }
  next()
})
export default router
