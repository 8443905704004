const state = {
  deviceTypeList: [],
  deviceFunctionalList: [
    {
      label: '广播',
      prop: 'broadcastSupport'
    },
    {
      label: '人证比对',
      prop: 'certificatesSupport'
    },
    {
      label: '云台控制',
      prop: 'cloudControlSupport'
    },
    {
      label: '人脸识别',
      prop: 'faceReSupport'
    },
    {
      label: '能否获取IMEI号',
      prop: 'getImeiSupport'
    },
    {
      label: '对讲',
      prop: 'intercomSupport'
    },
    {
      label: '直播',
      prop: 'liveSupport'
    },
    {
      label: '定位',
      prop: 'locationSupport'
    },
    {
      label: '监控',
      prop: 'monitorSupport'
    },
    {
      label: 'NFC',
      prop: 'nfcSupport'
    },
    {
      label: 'onvif',
      prop: 'onvifSupport'
    },
    {
      label: 'OTA',
      prop: 'otaUpdateSupport'
    },
    {
      label: '录像',
      prop: 'recSupport'
    },
    {
      label: 'RTSP',
      prop: 'rtspSupport'
    },
    {
      label: '传感器',
      prop: 'sensorSupport'
    },
    {
      label: '拍照',
      prop: 'takePhotoSupport'
    },
    {
      label: '上位机',
      prop: 'ucSupport'
    },
    {
      label: 'WebRtc',
      prop: 'webRtcSupport'
    },
    {
      label: '倍焦控制功能',
      prop: 'zoomControlSupport'
    },
    {
      label: '视频通话功能',
      prop: 'videoCallSupport'
    },
    {
      label: '支持登录',
      prop: 'loginSupport'
    },
    {
      label: '支持下发文件',
      prop: 'sendFileSupport'
    },
    {
      label: '支持下发消息',
      prop: 'sendMsgSupport'
    },
    {
      label: '支持规则',
      prop: 'ruleSupport'
    },
    {
      label: '支持任务',
      prop: 'taskSupport'
    },
    {
      label: '车辆识别',
      prop: 'carReSupport'
    },
    {
      label: '远程升级APK',
      prop: 'apkUpdateSupport'
    },
    {
      label: '国标设备',
      prop: 'gbDevice'
    },
    {
      label: '支持监听',
      prop: 'monitorListenSupport'
    },
    {
      label: '是国标下级平台设备',
      prop: 'gbPlatformDevice'
    },
    {
      label: '支持查看本机媒体文件',
      prop: 'clfSupport'
    },
    {
      label: '支持远程录音',
      prop: 'soundRecordingSupport'
    },
    {
      label: '支持远程重启',
      prop: 'restartSupport'
    },
    {
      label: '支持远程关机',
      prop: 'shutdownSupport'
    }
  ]
}

const getters = {
  deviceTypeList (state) {
    return state.deviceTypeList
  },
  deviceFunctionalList (state) {
    return state.deviceFunctionalList
  },
  /**
   * excludeAllType 不包含全部类型
   */
  deviceTypeListExcludeAllType () {
    return state.deviceTypeList.filter((item) => item.deviceType !== '')
  }
}

const mutations = {
  setDeviceTypeList (state, payload) {
    state.deviceTypeList = payload
  },
  /**
   * 获取并更新设备类型列表
   * @param state
   * @param payload 传入当前Vue对象
   */
  getDeviceType (state, payload) {
    const { _this } = payload
    _this.$api.findAll('sysDeviceType').then((res) => {
      this.commit('setDeviceTypeList', [
        {
          deviceType: '',
          typeName: '全部',
          deviceTypeName: '全部'
        },
        ...res.data
      ])
    })
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
