import http from '@/http'

export default {
  ptBillProjectByBillNo (params) {
    return new Promise((resolve, reject) => {
      http.post('/platform/billProject/byBillNo', params).then((res) => {
        resolve(res)
      })
    })
  }
}
