import http from '@/http'

export default {
  findByIds (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/findByIds', params).then((res) => {
        resolve(res)
      })
    })
  },
  updateBatch (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/updateBatch', params).then((res) => {
        resolve(res)
      })
    })
  },
  findProdModel (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/findProdModel', params).then((res) => {
        resolve(res)
      })
    })
  },
  /**
   * 兼容处理
   * @param params
   * @returns {Promise<unknown>}
   */
  findDeviceModel (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/findAll', params).then((res) => {
        resolve({
          data: res.data.map((item) => item.deviceModel)
        })
      })
      // http.post('/sysDevice/findDeviceModel', params).then(res => {
      //   resolve(res)
      // })
    })
  },
  imeiStatistics (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/imeiStatistics', params).then((res) => {
        resolve(res)
      })
    })
  },
  findByDeviceModel (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/findByDeviceModel', params).then((res) => {
        resolve(res)
      })
    })
  },
  checkImei (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysDevice/checkImei', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  queryDeviceModelList (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysDevice/queryDeviceModelList', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  getDeviceModelList (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysDevice/getDeviceModelList', params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  insert (params) {
    return new Promise((resolve, reject) => {
      http.post('/sysDevice/insert', params, { isJSON: true }).then((res) => {
        resolve(res)
      })
    })
  }
}
