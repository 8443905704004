import http from '@/http'

export default {
  updateBatch (params) {
    return new Promise((resolve, reject) => {
      http
        .post('/sysCloudCardState/updateBatch', params, { isJSON: true })
        .then((res) => {
          resolve(res)
        })
    })
  }
}
