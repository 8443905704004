import http from '@/http'

export default {
  update (params) {
    return new Promise((resolve, reject) => {
      http.post('/appBootPage/update', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  },
  select (params) {
    return new Promise((resolve, reject) => {
      http.post('/appBootPage/select', params, { isJSON: true }).then(res => {
        resolve(res)
      })
    })
  }
}
